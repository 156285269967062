<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="blog-dteails-content blog-border">
          <div class="blog-details-top">
            <!-- <span>Coste efectivo de los servicios prestados por el Ayuntamiento</span> -->
            <h2 class="title">
              Coste efectivo de los servicios prestados por el Ayuntamiento año
              2019
            </h2>
            <ul>
              <li><i class="fal fa-eye"></i> 232 Vistas</li>
              <li><i class="fal fa-comments"></i> 0 Comentarios</li>
              <li><i class="fal fa-calendar-alt"></i> 20 diciembre 2020</li>
            </ul>
          </div>

          <p
            style="
              text-align: justify;
              color: black;
              padding-top: 4px;
              padding-bottom: 10px;
            "
          >
            Todos los datos se han obtenido del
            <a
              href="https://drive.google.com/file/d/1eqxFTgHqgkLtVUcZdFRdFxqEyEQDIWgA/view?usp=sharing"
              target="_blank"
            >
              informe emitido por el servicio económico-financiero del
              Ayuntamiento de Jerez</a
            ><br /><br />

            Para el cálculo del coste efectivo de los servicios, el Ministerio
            de Hacienda y Función Pública ha desarrollado la
            <a
              href="https://www.boe.es/eli/es/o/2014/11/06/hap2075"
              target="_blank"
              >Orden HAP/2075/2014 de 6 de noviembre</a
            >
            por la que se establecen los criterios de cálculo del coste efectivo
            de los servicios prestados por las entidades locales. La citada
            Orden considera que los costes deben estar integrados por costes
            reales directos e indirectos que se deriven de la ejecución de
            gastos. <br />
            En consecuencia, se identifica con las obligaciones reconocidas,
            incluyendo también las obligaciones pendientes de aplicación a
            presupuesto, conforme a los datos de ejecución de gasto de los
            presupuestos generales de las entidades locales, y en su caso, con
            las cuentas anuales aprobadas de las entidades y organismos
            vinculados o dependientes, correspondientes al ejercicio inmediato
            anterior a aquel en el que el coste efectivo se vaya a calcular,
            comunicar y publicar.<br />
          </p>

          <div style="color: black">
            <p>
              Se establecen diferentes categorias de estos servicios: <br />
              En función de de la forma de gestionarlos:
            </p>
            <ul style="padding-left: 40px">
              <li><i class="fal fa-check"></i> Gestión directa.</li>
              <li><i class="fal fa-check"></i> Gestión indirecta.</li>
            </ul>

            <p>En función de la obligatoriedad de prestarlos:</p>
            <ul style="padding-left: 40px">
              <li><i class="fal fa-check"></i> Prestación obligatoria.</li>
              <li><i class="fal fa-check"></i> Competencias propias.</li>
            </ul>
          </div>

          <div class="blog-details-bath">
            <h4 class="title">
              Servicios de prestación obligatoria para los municipios
            </h4>
            <p>
              La forma de gestionar los servicios obligatorios en el
              Ayuntamiento de Jerez se muestra en la siguiente tabla:
              <img
                src="assets/images/blog/entrada1/gestionServiciosObligatorios.png"
                alt="blog-details"
              />
            </p>

            <p>
              Siendo el coste efectivo de estos servicios:
              <img
                src="assets/images/blog/entrada1/costeServiciosObligatorios.png"
                alt="blog-details"
              />
            </p>

            <h4 class="title">
              Servicios correspondientes a competencias propias de los
              municipios
            </h4>

            <p>
              La forma de gestionar los servicios correspondientes a
              competencias propias en el Ayuntamiento de Jerez se muestra en la
              siguiente tabla:
              <img
                src="assets/images/blog/entrada1/gestionServiciosCompetencia.png"
                alt="blog-details"
              />
            </p>

            <p>
              Siendo el coste efectivo de estos servicios:
              <img
                src="assets/images/blog/entrada1/costeServiciosCompetencia.png"
                alt="blog-details"
              />
            </p>
          </div>

          <div class="blog-details-confarance">
            <div class="confarance-flex d-flex mt-20 mb-20">
              <div class="confarance-thumb-1">
                <img src="assets/images/blog/entrada1/entidades.png" alt="" />
              </div>
              <div class="confarance-thumb-2">
                <p>
                  Algunos de los servicios se prestan a traves de otras
                  entidades como:
                </p>
                <ul style="padding-left: 40px">
                  <li><i class="fal fa-check"></i>COMUJESA</li>
                  <li><i class="fal fa-check"></i>EMUVIJESA</li>
                  <li><i class="fal fa-check"></i>MERCAJEREZ</li>
                  <li>
                    <i class="fal fa-check"></i>FUNDACIÓN CENTRO ASILO SAN JOSÉ
                  </li>
                  <li>
                    <i class="fal fa-check"></i>FUNDACIÓN CABALLERO BONALD
                  </li>
                  <li>
                    <i class="fal fa-check"></i> FUNDACIÓN CULTURAL
                    UNIVERSITARIA DE LAS ARTES DE JEREZ
                  </li>
                  <li>
                    <i class="fal fa-check"></i>FUNDACIÓN ANDRÉS DE RIBERA
                  </li>
                </ul>
              </div>
            </div>

            <div class="blog-details-bath">
              <h4 class="title">Unidades utilizadas</h4>
              <img
                src="assets/images/blog/entrada1/unidades.png"
                alt="blog-details"
              />
            </div>


            <div class="blog-details-bath">
              <h4 class="title">Comparativa años anteriores</h4>
              <img
                src="assets/images/blog/entrada1/comparativa.png"
                alt="blog-details"
              />
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
