<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    JEREZ SE VENDE POR SÓLO 14.999 EUROS…
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 30 noviembre 2021</li>
                </ul>
            </div>

            <p>
                Imagine por un momento que tuviera usted un par de hijos.
                <br>
                <br />
                Estará usted de acuerdo conmigo en que, el día uno de enero de cada año, usted tendría muy claro que sus
                hijos iban a tener la buena y sana costumbre de comer todos y cada uno de los días del año. Y usted
                contaría con que tendría que gastar en su alimentación durante todo el año.
                <br>
                <br />
                Todos podemos estar de acuerdo con la anterior afirmación. Pero en Jerez las cosas son distintas…
                <br>
                <br />
                Y es que en Jerez tenemos una Alcaldesa que tiene la responsabilidad de los animales del Zoológico que,
                a diferencia de lo que le pasa a los hijos de mi anterior ejemplo, pareciera que sólo tienen necesidad
                de comer durante los próximos tres meses…
                <br>
                <br />
                Pero ahora dejemos las bromas e ironías aparte, porque la cosa no tiene ninguna gracia. Se lo explicaré
                con breves preguntas y respuestas.
                <br>
                <br>
                Cada uno de enero el Ayuntamiento sabe que los animales del Zoo van a tener que comer todos los días del
                año. Por tanto, sabe que va a tener que comprar comida durante todo el año. Es una necesidad única e
                indiscutible.
                <br>
                <br />
                <strong>¿Por qué, entonces, el Ayuntamiento no contrata toda la alimentación del año de una sola vez (o
                    incluso
                    para más de un año, siempre y cuando esté dentro de su mandato), consiguiendo así unas condiciones
                    más
                    favorables (y es que cuanto más compras, más posibilidades tienes de que te apliquen mejor precio)?
                    <br>
                    <br>
                    ¿Por qué opta por contratos sucesivos que, en todos los casos, tienen un importe de casi 15.000
                    euros,
                    pero nunca superiores?</strong>
                <br>
                <br />
                La palabra mágica es: <strong>CONTRATOS MENORES</strong>. Se lo explico.
                <br>
                <br />
                Sin entrar en detalles y resumiendo, la normativa aplicable (Ley de Contratos del Sector Público), hace
                que, por debajo de esa cifra mágica de 15.000 euros, el ayuntamiento pueda adjudicar a discreción los
                contratos, sin estar sometido a demasiados controles. Para que me entiendan, que se lo dan a quien
                quieren, sin dar muchas más explicaciones.
                <br>
                <br />
                <strong>Pero, ¿estamos indefensos los ciudadanos ante estas arbitrariedades?</strong>
                <br>
                <br />
                En teoría no. Se supone que la propia Junta de Gobierno Local (presidida por Mamen Sánchez) debe
                “justificar de manera motivada la necesidad del contrato y que no se está alterando su objeto con el fin
                de evitar la aplicación” de otras formas de contrato, que someterían al ayuntamiento a obligaciones más
                rigurosas en lo que a la selección de alternativas y publicidad de las mismas se refiere.
                <br>
                <br />
                Eso quiere decir que nuestra Alcaldesa tiene una explicación para justificar que, a pesar de que los
                animales del Zoológico comen todos los días del año, su necesidad de comida sólo se produce de quince en
                quince mil euros. Estamos deseando que nos lo explique a todos los jerezanos…
                <br>
                <br />
                <strong>Y, si la Junta de Gobierno Local incumple sus obligaciones, ¿estamos indefensos?</strong>
                <br>
                <br />
                Pues se supone que no. A falta de uno, resulta que hay tres organismos supervisores de la actividad del
                equipo de gobierno: la Intervención Municipal, La Cámara de Cuentas (autonómica), y el Tribunal de
                Cuentas (estatal). Pero, como ya les explicamos recientemente con ocasión de la aprobación de la Cuenta
                General, la experiencia indica que, al menos hasta este momento, los órganos supervisores parecen estar
                poniéndose un poco de perfil en lo que a los problemas de Jerez se refiere. Esperemos que las cosas
                empiecen a cambiar en breve.
                <br>
                <br />
                <strong>Pero, ¿y esto es un incidente aislado que sólo afecta a la comida de los animales?</strong>
                <br>
                <br />
                Por desgracia nos tememos que no, y que el problema del fraccionamiento injustificado de contratos para
                evitar los controles legales se encuentra al orden del día en la práctica totalidad de los niveles de
                las administraciones públicas.
                <br>
                <br />
                Sin ir más lejos, y en las últimas semanas, aquí en Jerez hemos asistido a la forma en la que, una
                campaña de difusión en medios, la de “Callejea Jerez en otoño”, por importe total de 56 mil euros,
                resulta que, “casualmente”, ha acabado adjudicándose mediante tres contratos de 14.900 euros cada uno, y
                otro de 11.175 euros. Y es que resulta que hay que hacer contratos distintos, <strong>PARA LA MISMA
                    CAMPAÑA</strong>,
                dependiendo de que se vaya a difundir en medios televisivos, impresos, radiofónicos o digitales… Si a
                usted esto le parece un cachondeo, que se encuentra muy cerca (si no dentro…) del fraude de ley, estamos
                totalmente de acuerdo con usted…
                <br>
                <br />
                Desde el OCM Jerez nos produce una profunda pena tener que dedicar su tiempo a informarle de estas
                cosas, pero no duden de que seguiremos con nuestro compromiso para con nuestra maravillosa ciudad, de
                dárselos a conocer.
                <br>
                <br />
                Porque, como ya nos enseñó hace siglos Virgilio, “Tu ne cede malis, sed contra, audentior ito” que, en
                español quiere decir, “Jamás cedas al mal, sino combátelo con más audacia”.
                <br>
                <br />
                Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
            </p>
        </div>
    </div>
</div>


<app-footer></app-footer>