<app-header></app-header>
<!--====== BANNER PART START ======-->
<div class="banner-active">
  <div class="single-banner bg_cover" style="background-image: url(assets/images/FotoCatedral.png)">
    <div class="banner-overlay">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div class="banner-content">
              <span data-animation="fadeInLeft" data-delay="0.5s">OCM Jerez</span>
              <h1 data-animation="fadeInLeft" data-delay="0.9s" class="title">
                Observatorio <br />
                Ciudadano<br />
                Municipal
              </h1>
              <p data-animation="fadeInLeft" data-delay="1.3s">
                Una iniciativa ciudadana para promover un gobierno abierto en
                Jerez.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="single-banner bg_cover" style="background-image: url(assets/images/OCMgalloAzul.jpg)">
    <div class="banner-overlay">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div class="banner-content">
              <h1 data-animation="fadeInLeft" data-delay="0.9s" class="title">
                Observatorio <br />
                Ciudadano<br />
                Municipal
              </h1>
              <p data-animation="fadeInLeft" data-delay="1.3s">
                Participación: queremos contribuir a desarrollar mecanismos para
                generar una sociedad participativa, fomentar las consultas
                ciudadanas y organizar debates públicos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="single-banner bg_cover" style="background-image: url(assets/images/OCMalcazar.jpg)">
    <div class="banner-overlay">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div class="banner-content">
              <h1 data-animation="fadeInLeft" data-delay="0.9s" class="title">
                Observatorio <br />
                Ciudadano<br />
                Municipal
              </h1>
              <p data-animation="fadeInLeft" data-delay="1.3s">
                Colaboración: queremos buscar y ayudar a la implantación de
                herramientas de colaboración que permitan a la ciudadanía
                desarrollar su participación de la mejor forma posible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== BANNER PART ENDS ======-->

<!--====== QUIENES SOMOS START ======-->
<div class="pt-110 pb-120">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0s">
        <div class="section-title">
          <span>¿Quienes somos?</span>
          <h2>
            Somos personas de Jerez interesadas en instaurar un gobieno abierto
            en nuestro Ayuntamiento
          </h2>
        </div>
      </div>

      <div class="col-lg-6 offset-lg-1 wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="0s">
        <div class="section-title">
          <p>
            Buscamos estimular la participación ciudadana para conseguir un
            gobierno municipal abierto, en el que la participación, la
            colaboración, la transparencia y la rendición de cuentas, todo ello
            basado en el fomento del uso de datos abiertos, sean las bases que
            permitan mejorar el funcionamiento de nuestro Ayuntamiento.
          </p>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 wow slideInUp" data-wow-duration=".5s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <i class="fal fa-laptop-code"></i>
          <h5 class="title">Participación</h5>
          <p>
            Contribuir a desarrollar mecanismos para generar una sociedad
            participativa:
          </p>
          <ul>
            <li>Fomentar las consultas ciudadanas.</li>
            <li>Organizar debates públicos.</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 wow slideInUp" data-wow-duration="1s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <i class="fal fa-fingerprint"></i>
          <h5 class="title">Colaboración</h5>
          <p>
            Buscar y ayudar a la implantación de herramientas de colaboración
            que permitan a la ciudadanía desarrollar su participación de la
            mejor forma posible.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 wow slideInUp" data-wow-duration="1.5s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <i class="fal fa-chalkboard"></i>
          <h5 class="title">Transparencia</h5>
          <p>
            Conseguir que la información sobre las actividades del Ayuntamiento
            este a disposición de la ciudadanía, de manera oportuna y en formato
            de datos abiertos.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 wow slideInUp" data-wow-duration="2s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <i class="fal fa-database"></i>
          <h5 class="title">Rendición de cuentas</h5>
          <p>Promover la creación de:</p>
          <ul>
            <li>Códigos de conducta.</li>
            <li>Mecanismos de prevención de los conflictos de intereses.</li>
            <li>Transparencia en compras públicas.</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 wow slideInUp" data-wow-duration="2s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <i class="fal fa-database"></i>
          <h5 class="title">Datos abiertos</h5>
          <p>Abiertos por defecto.</p>
          <ul>
            <li>Exhaustivos.</li>
            <li>Accesibles y utilizables.</li>
            <li>Comparables.</li>
            <li>
              Que sirvan para mejorar la gobernanza y la participación
              ciudadana.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="what-we-are-shape-1">
        <img src="assets/images/what-we-are-shape-1.png" alt="shape">
    </div>
    <div class="what-we-are-shape-2">
        <img src="assets/images/what-we-are-shape-2.png" alt="shape">
    </div> -->
</div>
<!--====== QUIENES SOMOS ENDS ======-->

<!--====== ENTRADAS BLOG START ======-->
<div class="latest-news-area gray-bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="section-title text-center">
          <span>BLOG</span>
          <h2 class="title">Lee nuestras últimas entradas</h2>
        </div>
      </div>
    </div>
  </div>

  <!-- container -->
  <div class="letast-news-grid white-bg ml-40 mr-40">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="latest-news">
            <div class="row">

              <!-- Inicio entrada  -->
              <div class="col-lg-12 col-md-6 mb-40 wow slideInUp" data-wow-duration="1s" data-wow-delay="0s">
                <h2 class="title">
                  A vueltas con Comujesa y los autobuses…
                </h2>
                <p>
                  <span>por</span> OCM
                  <span style="color: rgb(37, 7, 87)">23 septiembre 2021</span>
                  <span style="color: red; font-weight: bold">&nbsp;&nbsp;Nueva entrada&nbsp;</span>
                  <i class="fal fa-exclamation-circle fa-2x"></i>
                  <br>
                </p>
                <p style="
                    text-align: justify;
                    color: black;
                    padding-top: 4px;
                    padding-bottom: 10px;
                 ">
                  En julio de 2020 nuestro Ayuntamiento anunció su intención de trasladar parte de los
                  servicios prestados por Comujesa a la parcela de la Ciudad del Transporte que en su
                  día iba a ocupar el fallido proyecto de Zahav. En concreto, el servicio de autobuses y
                  el de calas y alumbrado público.
                </p>
                <a class="main-btn" routerLink="/comujesa202109">Leer más... <i class="fal fa-long-arrow-right"></i></a>
              </div>
              <!-- Final entrada  -->

              <!-- Inicio entrada  -->
              <div class="col-lg-12 col-md-6 mb-40 wow slideInUp" data-wow-duration="1s" data-wow-delay="0s">
                <div class="blog-left">
                  <p>
                    <span>por</span> OCM
                    <span style="color: rgb(37, 7, 87)">10 septiembre 2021</span>

                  </p>
                </div>
                <h2 class="title">
                  Lo que se ve, y lo que no se ve……
                </h2>
                <p style="
                  text-align: justify;
                  color: black;
                  padding-top: 4px;
                  padding-bottom: 10px;
               ">
                  Es probable que dentro de unos días el pleno del Ayuntamiento de Jerez apruebe la solicitud
                  para 2022 de un nuevo préstamo de 9 millones de euros, para el pago de sentencias a pagar a
                  antiguos proveedores, con la posibilidad de que el préstamo total aumente hasta los 19 millones de
                  euros.
                </p>
                <a class="main-btn" routerLink="/loQueSeVe">Leer más... <i class="fal fa-long-arrow-right"></i></a>
              </div>
              <!-- Final entrada  -->

              <!-- Inicio entrada  -->
              <div class="col-lg-12 col-md-6 mb-40 wow slideInUp" data-wow-duration="1s" data-wow-delay="0s">
                <div class="blog-left">
                  <p>
                    <span>por</span> OCM
                    <span style="color: rgb(37, 7, 87)">17 agosto 2021</span>
                  </p>
                </div>
                <h2 class="title">
                  Lo que no te han contado del presupuesto…
                </h2>
                <p style="
                  text-align: justify;
                  color: black;
                  padding-top: 4px;
                  padding-bottom: 10px;
               ">
                  Hace ya más de dos semanas que la Delegada de Economía del Ayuntamiento de Jerez, Laura Álvarez,
                  compareció
                  ante los medios para informar del, a su juicio, excelente resultado de la liquidación del presupuesto
                  de
                  2020: 30 millones euros de superávit presupuestario, mejora de 11 millones del excedente de tesorería,
                  reducción de la deuda con proveedores…
                </p>
                <a class="main-btn" routerLink="/blogentrada3">Leer más... <i class="fal fa-long-arrow-right"></i></a>
              </div>
              <!-- Final entrada  -->

              <!-- Inicio entrada  -->
              <div class="col-lg-12 col-md-6 mb-40 wow slideInUp" data-wow-duration="1s" data-wow-delay="0s">
                <div class="blog-left">
                  <p>
                    <span>por</span> OCM
                    <span style="color: rgb(37, 7, 87)">26 diciembre 2020</span>
                  </p>
                </div>
                <h2 class="title">
                  Costes por concesión de licencias en el Ayuntamiento de Jerez.
                </h2>
                <p style="
                    text-align: justify;
                    color: black;
                    padding-top: 4px;
                    padding-bottom: 10px;
                  ">
                  Se recogen los datos referentes a la concesión de licencias y
                  se trata de identificar los costes de esta actividad.
                </p>
                <a class="main-btn" routerLink="/blogentrada2">Leer más... <i class="fal fa-long-arrow-right"></i></a>
              </div>
              <!-- Final entrada  -->

              <!-- Inicio entrada -------------------------------------------------------------------------------------- -->
              <div class="col-lg-12 col-md-6 wow slideInUp" data-wow-duration=".5s" data-wow-delay="0s">
                <div class="blog-left">
                  <p>
                    <span>por</span> OCM
                    <span style="color: rgb(37, 7, 87)">26 diciembre 2020</span>
                  </p>
                  <!-- <img style = "width: 32px; height: 32px" src="assets/images/OCMicono.ico" alt=""> -->
                </div>
                <h2 class="title">
                  Coste efectivo de los servicios prestados por el Ayuntamiento
                  año 2019.
                </h2>
                <!-- <ul>
                                  <li><i class="fal fa-eye"></i> 232 Vistas</li>
                                  <li><i class="fal fa-comments"></i> 0 Comentarios</li>
                                  <li><i class="fal fa-calendar-alt"></i>20 diciembre 2020</li>
                              </ul> -->

                <p style="
                    text-align: justify;
                    color: black;
                    padding-top: 4px;
                    padding-bottom: 10px;
                  ">
                  Todos los datos se han obtenido del
                  <a href="https://drive.google.com/file/d/1eqxFTgHqgkLtVUcZdFRdFxqEyEQDIWgA/view?usp=sharing"
                    target="_blank">
                    informe emitido por el servicio económico-financiero del
                    Ayuntamiento de Jerez</a><br /><br />

                  Para el cálculo del coste efectivo de los servicios, el
                  Ministerio de Hacienda y Función Pública ha desarrollado la
                  <a href="https://www.boe.es/eli/es/o/2014/11/06/hap2075" target="_blank">Orden HAP/2075/2014 de 6 de
                    noviembre</a>
                  por la que se establecen los criterios de cálculo del coste
                  efectivo de los servicios prestados por las entidades locales.
                  La citada Orden considera que los costes deben estar
                  integrados por costes reales directos e indirectos que se
                  deriven de la ejecución de gastos. <br />
                  En consecuencia, se identifica con las obligaciones
                  reconocidas, incluyendo también las obligaciones pendientes de
                  aplicación a presupuesto, conforme a los datos de ejecución de
                  gasto de los presupuestos generales de las entidades locales,
                  y en su caso, con las cuentas anuales aprobadas de las
                  entidades y organismos vinculados o dependientes,
                  correspondientes al ejercicio inmediato anterior a aquel en el
                  que el coste efectivo se vaya a calcular, comunicar y
                  publicar.<br />
                </p>

                <a class="main-btn" routerLink="/blogentrada1">Leer más... <i class="fal fa-long-arrow-right"></i></a>
              </div>
              <!-- Final entrada  -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== ENTRADAS BLOG ENDS ======-->

<!--====== APLICACIONES TITLE START ======-->
<div class="services-title-area" style="margin-top: 50px;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="services-title-item text-center">
          <div class="ring-shape"></div>
          <span>Nuestras últimas aplicaciones</span>
          <h3 class="title">Porque creemos en los datos abiertos</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== APLICACIONES TITLE ENDS ======-->

<!--====== APLICACIONES START ======-->
<div class="latest-services-area">
  <div class="container">
    <!-- <div class="row justify-content-center"> -->
    <div class="mam-row">


      <div class="col-lg-3 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0s" style="
          background-color: rgb(207, 201, 201);
          margin-right: 8px;
          margin-bottom: 8px;
        ">

        <div class="single-services mt-30 mb-55">
          <div class="services-thumb">
            <img src="assets/images/aplicaciones/OCMeje2020.png" alt="" />
          </div>
          <div class="services-content">
            <h4>Liquidaciones presupuestos años 2017 al 2020</h4>
            <a href="http://eje2020.ocmjerez.org/">Abrir aplicación <i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0s" style="
          background-color: rgb(207, 201, 201);
          margin-right: 8px;
          margin-bottom: 8px;
        ">
        <div class="single-services mt-30 mb-55">
          <div class="services-thumb">
            <img src="assets/images/aplicaciones/OCMpre2020.png" alt="" />
          </div>
          <div class="services-content">
            <h4>Presupuesto 2020</h4>
            <br>
            <br>
            <a href="http://pre2020.ocmjerez.org">Abrir aplicación <i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0s" style="
          background-color: rgb(207, 201, 201);
          margin-right: 8px;
          margin-bottom: 8px;
        ">

        <div class="single-services mt-30 mb-55">
          <div class="services-thumb">
            <img src="assets/images/aplicaciones/OCMopa2020.png" alt="" />
          </div>
          <div class="services-content">
            <h4>OPA 2020</h4>
            <br>
            <br>
            <a href="http://opa.ocmjerez.org">Abrir aplicación<i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration=".5s" data-wow-delay="0s" style="
      background-color: rgb(207, 201, 201);
      margin-right: 8px;
      margin-bottom: 8px;
    ">

        <div class="single-services mt-30 mb-55">
          <div class="services-thumb">
            <img src="assets/images/aplicaciones/OCMinterrogacion.jpg" alt="" />
          </div>
          <div class="services-content">
            <h4>Presupuesto 2021</h4>
            <!-- <span>14 diciembre 2020</span> -->
            <p>Se prologa el presupuesto del 2020.</p>
            <!-- <a routerLink='/service-detail'>Leer más... <i class="fal fa-long-arrow-right"></i></a> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!--====== APLICACIONES ENDS ======-->

<!--====== INFORMES START ======-->
<div class="case-studies-area">
  <div class="latest-news-area gray-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center">
            <span>INFORMES</span>
            <h2 class="title">Lee nuestros últimos informes recopilados</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid pl-70 pr-70"> -->
    <div class="mam-row mb-55">
      <div class="col-lg-3 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0s" style="
      background-color: rgb(207, 201, 201);
      margin-right: 8px;
      margin-bottom: 8px;
    ">
        <div class="single-services mt-30 mb-55">
          <div class="services-thumb">
            <!-- <img src="assets/images/aplicaciones/OCMeje2020.png" alt="" /> -->
          </div>
          <div class="services-content">
            <div>
              <div class="item">
                <span>AIReF </span>
                <h5>
                  Diciembre 2020. Informe <br />
                  entidades locales
                </h5>
              </div>
              <a href="https://drive.google.com/file/d/1wOmy-1YZ85INOneYFnR6NXyLce5ncd1k/view?usp=sharing"
                target="_blank">Abrir informe<i class="fal fa-long-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0s" style="
      background-color: rgb(207, 201, 201);
      margin-right: 8px;
      margin-bottom: 8px;
    ">
        <div class="single-services mt-30 mb-55">
          <div class="services-thumb">
            <!-- <img src="assets/images/aplicaciones/OCMeje2020.png" alt="" /> -->
          </div>
          <div class="services-content">
            <div>
              <div class="item">
                <span>Tribunal de Cuentas</span>
                <h5>Julio 2021<br> Informe fiscalización del sector público.<br>Ejercicio 2019 </h5>
              </div>
              <a href="https://drive.google.com/file/d/14WC1tcOD35brYEBgPSWDcB_16-R6wupt/view?usp=sharing"
                target="_blank">Abrir informe<i class="fal fa-long-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====== INFORMES ENDS ======-->

    <app-footer></app-footer>