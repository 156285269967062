<div class="blog-sidebar-area ml-20">
    <div class="blog-sidebar-item mt-30">
        <div class="sidebar-title">
            <h4 class="title">About Me</h4>
        </div>
        <div class="sidebar-about-contnent text-center mt-35">
            <img src="assets/images/blog-about.png" alt="">
            <h6 class="title">Rosalina D. Willaimson</h6>
            <p>Lorem ipsum dolor sit amet, consecte-tur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
            <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
            </ul>
        </div>
    </div>
    <div class="blog-sidebar-item mt-30">
        <div class="sidebar-title">
            <h4 class="title">Search Objects</h4>
        </div>
        <div class="sidebar-search-item text-center mt-35">
            <form action="#">
                <div class="input-box">
                    <input type="text" placeholder="Search your keyword...">
                    <button><i class="fal fa-search"></i></button>
                </div>
            </form>
        </div>
    </div>
    <div class="blog-sidebar-item mt-30">
        <div class="sidebar-title">
            <h4 class="title">Popular Feeds</h4>
        </div>
        <div class="sidebar-feeds mt-45">
            <div class="sidebar-feeds-item mt-30 pl-100">
                <a routerLink='/blog-detail'>
                    <h4 class="title">Lorem ipsum dolor sit cing elit, sed do.</h4>
                </a>
                <span><i class="fal fa-calendar-alt"></i>24th March 2019</span>
                <img src="assets/images/feeds-1.png" alt="">
            </div>
            <div class="sidebar-feeds-item mt-30 pl-100">
                <a routerLink='/blog-detail'>
                    <h4 class="title">Lorem ipsum dolor sit cing elit, sed do.</h4>
                </a>
                <span><i class="fal fa-calendar-alt"></i>24th March 2019</span>
                <img src="assets/images/feeds-2.png" alt="">
            </div>
            <div class="sidebar-feeds-item mt-30 pl-100">
                <a routerLink='/blog-detail'>
                    <h4 class="title">Lorem ipsum dolor sit cing elit, sed do.</h4>
                </a>
                <span><i class="fal fa-calendar-alt"></i>24th March 2019</span>
                <img src="assets/images/feeds-3.png" alt="">
            </div>
            <div class="sidebar-feeds-item mt-30 pl-100">
                <a routerLink='/blog-detail'>
                    <h4 class="title">Lorem ipsum dolor sit cing elit, sed do.</h4>
                </a>
                <span><i class="fal fa-calendar-alt"></i>24th March 2019</span>
                <img src="assets/images/feeds-4.png" alt="">
            </div>
        </div>
    </div>
    <div class="blog-sidebar-item mt-30">
        <div class="sidebar-title">
            <h4 class="title">Categories</h4>
        </div>
        <div class="sidebar-categories mt-35">
            <ul>
                <li><a routerLink='/blog-detail'>Business<span>26</span></a></li>
                <li><a routerLink='/blog-detail'>Consultant<span>30</span></a></li>
                <li><a routerLink='/blog-detail'>Creative<span>71</span></a></li>
                <li><a routerLink='/blog-detail'>UI/UX<span>56</span></a></li>
                <li><a routerLink='/blog-detail'>Technology<span>60</span></a></li>
            </ul>
        </div>
    </div>
    <div class="blog-sidebar-item mt-30">
        <div class="sidebar-title">
            <h4 class="title">Never Miss News</h4>
        </div>
        <div class="sidebar-social text-center mt-35">
            <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
            </ul>
        </div>
    </div>
    <div class="blog-sidebar-item mt-30">
        <div class="sidebar-title">
            <h4 class="title">Twitter Feeds</h4>
        </div>
        <div class="sidebar-twitter-feeds mt-35">
            <div class="sidebar-twitter-feeds-item">
                <ul>
                    <li><a href="#">Rescue - #Gutenberg ready @ wordpress</a></li>
                    <li><a href="#">Theme for Creative Bloggers available on</a></li>
                    <li><a href="#">@ ThemeForest https://t.co/2r1POjOjgV</a></li>
                    <li><a href="#">C… https://t.co/rDAnPyClu1</a></li>
                </ul>
                <span>November 25, 2018</span>
                <i class="fab fa-twitter"></i>
            </div>
            <div class="sidebar-twitter-feeds-item">
                <ul>
                    <li><a href="#">Rescue - #Gutenberg ready @ wordpress</a></li>
                    <li><a href="#">Theme for Creative Bloggers available on</a></li>
                    <li><a href="#">@ ThemeForest https://t.co/2r1POjOjgV</a></li>
                    <li><a href="#">C… https://t.co/rDAnPyClu1</a></li>
                </ul>
                <span>November 25, 2018</span>
                <i class="fab fa-twitter"></i>
            </div>
            <div class="sidebar-twitter-feeds-item">
                <ul>
                    <li><a href="#">Rescue - #Gutenberg ready @ wordpress</a></li>
                    <li><a href="#">Theme for Creative Bloggers available on</a></li>
                    <li><a href="#">@ ThemeForest https://t.co/2r1POjOjgV</a></li>
                    <li><a href="#">C… https://t.co/rDAnPyClu1</a></li>
                </ul>
                <span>November 25, 2018</span>
                <i class="fab fa-twitter"></i>
            </div>
        </div>
    </div>
    <div class="blog-sidebar-item mt-30">
        <div class="sidebar-title">
            <h4 class="title">Instagram Feeds</h4>
        </div>
        <div class="sidebar-instagram mt-35">
            <ul>
                <li><a href="#"><img src="assets/images/instagram-1.jpg" alt=""></a></li>
                <li><a href="#"><img src="assets/images/instagram-2.jpg" alt=""></a></li>
                <li><a href="#"><img src="assets/images/instagram-3.jpg" alt=""></a></li>
            </ul>
            <ul>
                <li><a href="#"><img src="assets/images/instagram-4.jpg" alt=""></a></li>
                <li><a href="#"><img src="assets/images/instagram-5.jpg" alt=""></a></li>
                <li><a href="#"><img src="assets/images/instagram-6.jpg" alt=""></a></li>
            </ul>
            <ul>
                <li><a href="#"><img src="assets/images/instagram-7.jpg" alt=""></a></li>
                <li><a href="#"><img src="assets/images/instagram-8.jpg" alt=""></a></li>
                <li><a href="#"><img src="assets/images/instagram-9.jpg" alt=""></a></li>
            </ul>
        </div>
    </div>
    <div class="blog-sidebar-item mt-30">
        <div class="sidebar-title">
            <h4 class="title">Tags</h4>
        </div>
        <div class="sidebar-tags mt-35">
            <ul>
                <li><a routerLink='/blog-detail'>Popular</a></li>
                <li><a routerLink='/blog-detail'>desgin</a></li>
                <li><a routerLink='/blog-detail'>ux</a></li>
            </ul>
            <ul>
                <li><a routerLink='/blog-detail'>usability</a></li>
                <li><a routerLink='/blog-detail'>develop</a></li>
                <li><a routerLink='/blog-detail'>icon</a></li>
            </ul>
            <ul>
                <li><a routerLink='/blog-detail'>business</a></li>
                <li><a routerLink='/blog-detail'>consult</a></li>
                <li><a routerLink='/blog-detail'>kit</a></li>
            </ul>
            <ul>
                <li><a routerLink='/blog-detail'>keyboard</a></li>
                <li><a routerLink='/blog-detail'>mouse</a></li>
                <li><a routerLink='/blog-detail'>tech</a></li>
            </ul>
        </div>
    </div>
    <div class="blog-sidebar-add mt-30">
        <img src="assets/images/sidebar-ad-2.jpg" alt="add">
        <div class="add-content">
            <span>350x600</span>
            <h5 class="title">Add Banner</h5>
        </div>
    </div>
</div>
