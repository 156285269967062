<app-header></app-header>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 id="demotext">Sobre nosotros</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/' style="color: white">Inicio </a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">About</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== PAGE TITLE PART ENDS ======-->

<!--====== ABOUT EXPERIENCE PART START ======-->
<div class="about-experience-area pb-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="experience-item">
                    <span>¿Quienes somos?</span>
                    <h3>Personas interesadas en promover un gobierno abierto en el Ayuntamiento de Jerez.</h3>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1">
                <div class="experience-item">
                    <span>¿Qué pretendemos lograr?</span>
                    <h3>Conseguir la implementación de las herramientas que permitan una participación
                        ciudadana y un ejercicio real de la transparencia en todos los asuntos municipales.
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== ABOUT EXPERIENCE PART ENDS ======-->

<!--====== ABOUT HISTORY PART START ======-->
<div class="about-history-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="section-title text-center">
                    <h2 class="title">Nuestra historia</h2>
                    <p>El camino hacia la obtención de un gobierno abierto para Jerez.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="history-item history-item-2">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                        <img src="assets/images/about/presentacion.png" alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                        <span>2017 - presentación</span>
                        <h4 class="title">Realizamos nuestra presentación en la UCA</h4>
                    </div>
                    <div class="number-box">
                        <span>01</span>
                    </div>
                </div>

                <div class="history-item history-item-2">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <img src="assets/images/about/congreso2019.png" alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <span>2019 - congreso</span>
                        <h4 class="title">Presentamos ponencia en el IV Congreso Internacional de Transparencia</h4>
                    </div>
                    <div class="number-box">
                        <span>03</span>
                    </div>
                </div>

                <div class="history-item history-item-2 pt-140">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <img src="https://res.cloudinary.com/dabrencx7/image/upload/v1630230815/blog/logo-congreso2021_r3jqa8.jpg"
                            alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <span>2021 - congreso</span>
                        <h4 class="title">Presentamos ponencia en el VI Congreso Internacional de Transparencia</h4>
                    </div>
                    <div class="number-box">
                        <span>05</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">

                <div class="history-item pt-240">
                    <!-- Solamente para separar -->
                </div>

                <div class="history-item  pt-140">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                        <img src="assets/images/about/congreso2018.png" alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                        <span>2018 - congreso</span>
                        <h4 class="title">Presentamos ponencia en el III Congreso Internacional de Transparencia</h4>
                    </div>
                    <div class="number-box">
                        <span>02</span>
                    </div>
                </div>

                <div class="history-item pt-140">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <img src="assets/images/about/jerezEnRed2019.png" alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <span>2019 - conferencia </span>
                        <h4 class="title">Colaboramos en la conferencia "Jerez ciudad socialmente catastrófica"</h4>
                    </div>
                    <div class="number-box">
                        <span>04</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--====== ABOUT HISTORY PART ENDS ======-->

    <!--====== FAQ PART START ======-->
    <div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/OCMplazaArenal.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="about-faq-content">
                        <span>FAQ</span>
                        <h2 class="title">Preguntas más frecuentes.</h2>
                    </div>
                    <div class="faq-accordion-3 mt-30">
                        <div class="accordion" id="accordionExample">
                            <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                                <div class="card-header" id="headingOne">
                                    <a class="" href="" data-toggle="collapse" data-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        <i class="fal fa-magic"></i>¿Como puedo colaborar?
                                    </a>
                                </div>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                    data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>Puedes hacerlo de muchas formas, buscando información, escribiendo informes
                                            sobre
                                            la documentación existente, colaborando en el diseño de la página.
                                            <br>Si estas interesado puedes enviar un correo a
                                            info@ocmjerez.org
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                                <div class="card-header" id="headingTwo">
                                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo">
                                        <i class="fal fa-magic"></i>¿El OCM depende del Ayuntamiento?
                                    </a>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                    data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>No tenemos ninguna relación de dependencia con el Ayuntamiento, <span
                                                style="color:red;  font-weight: bold">ni tampoco con partido político
                                                alguno.</span></p>
                                    </div>
                                </div>
                            </div>

                            <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                                <div class="card-header" id="headingThree">
                                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseThree">
                                        <i class="fal fa-magic"></i>¿Como os financias?
                                    </a>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                    data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>Unicamente con las cuotas de las personas socias. <span
                                                style="color:red;  font-weight: bold">No recibimos ninguna
                                                subvención.</span></p>
                                    </div>
                                </div>
                            </div>

                            <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                                <div class="card-header" id="headingFour">
                                    <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour"
                                        aria-expanded="false" aria-controls="collapseFour">
                                        <i class="fal fa-magic"></i>¿Son públicos vuestros estatutos?
                                    </a>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                    data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>¡Claro!, puedes leerlos en este <a
                                                href="https://drive.google.com/file/d/1PTl2iQaPohpUslFkI0NnZgEJWgyucTnY/view?usp=sharing"
                                                style="padding-left: 4px;" target="_blank"
                                                rel="nofollow"><i></i>enlace</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--====== FAQ PART ENDS ======-->
    <app-footer></app-footer>