<app-header></app-header>

<!--====== BLOG STANDARD PART START ======-->
<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <!-- <div class="blog-standard"> -->
                    <!-- <div class="single-blog-standard  mt-30"> -->
                        <div class="blog-dteails-content blog-border">
                            <div class="blog-details-top">
                                <!-- <span>Coste efectivo de los servicios prestados por el Ayuntamiento</span> -->
                                <h2 class="title">Coste efectivo de los servicios prestados por el Ayuntamiento</h2>
                                <ul>
                                    <li><i class="fal fa-eye"></i> 232 Vistas</li>
                                    <li><i class="fal fa-comments"></i> 0 Comentarios</li>
                                    <li><i class="fal fa-calendar-alt"></i> 20 diciembre 2020</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                                <p class="mt-m2">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                            </div>

                            <div class="blog-details-bath">
                                <img src="assets/images/OCMplanta.jpg" alt="blog-details">
                                <h4 class="title">Servicios de prestación obligatoria para los municipios</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitati-on ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre-henderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. </p>

                                <h4 class="title">Servicios correspondientes a competencias propias de los municipios</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitati-on ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre-henderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. </p>

                                <!-- <h4 class="title">Setting the mood with incense</h4> -->
                                <!-- <ul>
                                    <li><i class="fal fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                    <li><i class="fal fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                    <li><i class="fal fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                    <li><i class="fal fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                    <li><i class="fal fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do.</li>
                                </ul> -->
                            </div>

                            <!-- <div class="blog-details-quote text-center mt-30 mb-30">
                                <p><span>by</span> Hetmayar</p>
                                <h3 class="title">Viral dreamcatcher keytar typewriter, aesthetic offal umami. Aesthetic polaroid pug pitchfork post-ironic.</h3>
                                <i class="fas fa-quote-left"></i>
                            </div> -->

                            <div class="blog-details-confarance">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem. </p>
                                <div class="confarance-flex d-flex mt-20 mb-20">
                                    <div class="confarance-thumb-1">
                                        <img src="assets/images/confarance.jpg" alt="">
                                    </div>
                                    <div class="confarance-thumb-2">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit .</p>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem. </p>
                            </div>


                        </div>
                    <!-- </div> -->
                <!-- </div> -->
            </div>

            <!-- <div class="col-lg-4 col-md-6 col-sm-8">
                <app-blogsidebar></app-blogsidebar>
            </div> -->

        </div>
    </div>
</div>
<!--====== BLOG STANDARD PART ENDS ======-->
<app-footer></app-footer>
