<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    JEREZ NO (les) INTERESA…
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 12 noviembre 2021</li>
                </ul>
            </div>

            <p>
                <br>
                <strong> No se equivoque: JEREZ SÍ INTERESA…</strong>
                <br />
                <br>
                La Feria, la Semana Santa, las zambombas, el flamenco, los vinos, los caballos, las motos… Jerez
                interesa y atrae con sus encantos a cientos de miles de personas cada año. A toda esa gente claro que
                les interesa.
                <br>
                De quienes les hablo es de otros. De los gobernantes de los distintos niveles de la administración que,
                con sus hechos, parecen demostrar que no tienen demasiado interés por el futuro de Jerez.
                <br />
                <br>
                <strong>¿No me creen?
                    Déjenme que les cuente.</strong>
                <br />
                <br />
                En estos días se ha publicado un informe de la Autoridad Independiente de Responsabilidad Fiscal
                (llamémosla AIReF), en la que se califica la situación financiera de Jerez (junto con Jaén y Parla) como
                “crítica”, que como la propia AIReF explica, significa que “a políticas constantes, no se estima posible
                el retorno a una situación (financiera) sostenible”. Eso en lenguaje de la calle quiere decir que, si no
                se cambia nada, Jerez no recuperará NUNCA, la estabilidad de sus cuentas.
                <br />
                <br />
                <strong> ¿Nos ha pillado por sorpresa esta valoración? En absoluto</strong>
                <br />
                <br />
                Ya a finales de 2017 la AIReF recomendaba que “el Ministerio de Hacienda convoque y lidere, para los
                Ayuntamientos de Jerez de la Frontera,… una comisión de expertos,…, en la que participe,…, la comunidad
                autónoma tuteladora, para analizar las causas por las cuales estos ayuntamientos tienen problemas de
                sostenibilidad y proponer las soluciones más adecuadas”.
                <br>
                Hace casi cuatro años…
                <br>
                <br>
                <strong> ¿Qué han hecho cada una de las administraciones interpeladas? Veamos…</strong>
                <br />
                <br>
                Empezando por nuestro propio ayuntamiento, lo que ha hecho ha sido, si cabe, peor que nada. Y es que
                simplemente ha negado la evidencia:
                <br>
                <br />
            </p>

            <ul>
                <li>
                    <p>

                        1. Repasen las declaraciones sobre la economía del ayuntamiento de sus responsables durante
                        estos cuatro
                        años, y no encontrarán más que declaraciones de “rumbo enderezado”, “cambio de la
                        tendencia”, “comienzo
                        de la recuperación”...
                    </p>
                    <br>
                </li>
                <li>
                    <p>

                        2. En la práctica, lo único que se ha hecho, como les hemos explicado hace semanas, es dar
                        la patada
                        hacia delante. Retrasar hasta junio de 2023 (justo después de las elecciones) cualquier pago
                        de la
                        asfixiante deuda, y gastarse se aquí a las elecciones todo lo que se pueda (y más…) para
                        salir
                        reelegidos. Y el que venga después “que se coma el marrón”. Eso, y echarle la culpa a un
                        equipo de
                        gobierno que dejó la alcaldía hace más de seis años…
                    </p>
                    <br>
                </li>
                <li>
                    <p>

                        3. Ya en el colmo del esperpento, tras el último informe de la AIReF la reacción, literal,
                        del
                        ayuntamiento ha sido decir que “pero todavía estamos mejor que Jaén y Parla”. Parece que,
                        más que salir
                        del pozo, lo que nos preocupa es que haya otros todavía más en el fondo…

                    </p>
                    <br>
                </li>

            </ul>
            <p>
                <br />
                <strong>¿Y el resto de administraciones? Pues…</strong>
                <br>
                <br>
                Desde el Ministerio de Hacienda, y eso que ha tenido dos “colores” políticos desde 2017, no se ha
                prestado ninguna atención a la recomendación de la AIReF, y la comisión, que se sepa, ni está ni se la
                espera. No es que seamos muy optimistas con eso de las comisiones (ya saben eso de “si quieres que el
                problema perdure, crea una comisión”), pero al menos permitiría que todos viéramos que el problema de
                esta ciudad (como de Jaén o Parla) no se puede solucionar sólo desde aquí (aunque a nosotros nos toque
                una parte muy importante del sacrificio…), y ni mucho menos se va a solucionar con mágicas proyecciones
                de hojas de Excel, que hay que cambiar cada poco tiempo…
                <br />

                <br />
                Pero es que tampoco los gobiernos de la Junta de Andalucía, que también ha tenido dos “colores”
                políticos desde 2017, y que tiene la responsabilidad de la supervisión de Jaén y Jerez (entre las dos
                hablamos de más de trescientos mil habitantes…), han mostrado excesivo interés por implicarse en la
                solución de un problema que, insisto, va más allá de una única administración…
                ¿Y los supervisores? Pues tampoco…
                <br />

                <br />
                Como ya les explicamos hace algunas semanas, desde hace años el ayuntamiento de Jerez está presentando
                unas cuentas en las que, según la opinión de la propia intervención municipal, se incumplen la mayoría
                de los principios contables que se deberían aplicar de manera obligatoria.
                <br />

                <br />
                A pesar de eso, durante todos estos años ni el Tribunal de Cuentas (Madrid), ni la Cámara de Cuentas de
                Andalucía, han tomado la más mínima acción para averiguar qué está pasando en Jerez, y cómo es posible
                que, año tras año, la intervención municipal detalle tan sorprendente lista de irregularidades
                contables, sin que pase nada…
                <br />
                <br />
                ¿Cuál es el resumen? Ya se lo dije. Pues que no les interesa…
                Han pasado ya cuatro años desde que la AIReF alertó sobre la situación de Jerez, y las administraciones
                interpeladas, así como los órganos supervisores, no han hecho nada realmente efectivo para empezar a
                responder al gravísimo reto económico que sufre Jerez (y Jaén y Parla…). Es evidente que se trata de un
                tema que implica mucho trabajo y sacrificio, y pocas fotos de las que tanto les gustan a nuestros
                dirigentes…
                Lo dicho, que a nuestros dirigentes no les interesa Jerez…
                <br />
                <br />
                Por nuestra parte, desde el OCM Jerez, seguiremos contándole todas estas cosas, que parece que nadie
                tiene interés por que se sepan.
                <br />
                <br />
                <br />
                Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
            </p>
        </div>
    </div>
</div>


<app-footer></app-footer>