import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-quien-le-importa',
  templateUrl: './a-quien-le-importa.component.html',

})
export class AQuienLeImportaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
