<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    Descontrol presupuestario.
                    <br>
                    Como gobernar sin rigor presupuestario alguno, o mejor aún, sin presupuesto.
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 2 diciembre 2021</li>
                </ul>
            </div>

            <p>
                El Real Decreto Legislativo 2/2004, de 5 de marzo, por el que se aprueba el texto refundido de la Ley
                Reguladora de las Haciendas Locales, define los presupuesto en su artículo 162:
                <br>
                <strong>Artículo 162. Definición.</strong>
                Los presupuestos generales de las entidades locales constituyen la expresión cifrada, conjunta y
                sistemática de las obligaciones que, como máximo, pueden reconocer la entidad, y sus organismos
                autónomos, y de los derechos que prevean liquidar durante el correspondiente ejercicio, así como de las
                previsiones de ingresos y gastos de las sociedades mercantiles cuyo capital social pertenezca
                íntegramente a la entidad local correspondiente.
                <br>
                <br />
                En definitiva, los presupuestos son el documento en el que se recoge la previsión anual de los ingresos
                y gastos del Ayuntamiento y constituyen uno de los instrumentos más importantes de la política económica
                del Gobierno Local, en los que se plasman los objetivos estratégicos de las distintas políticas públicas
                y los recursos asignados para su cumplimiento.
                <br>
                <br />
                El presupuesto debería ser, por tanto, la base de todas las decisiones de cualquier buen gestor, sin
                embargo, nuestro Gobierno Local parece sentirse muy cómodo en el descontrol presupuestario, sin
                importarle gobernar en el 2021 con el presupuesto prorrogado del 2020 y sin que se tenga constancia de
                que piense aprobar el del 2022.
                <br>
                <br />

                A nuestro entender el Gobierno Local prefiere gestionar sin presupuesto ya que esto le permite ir
                tomando decisiones a salto de mata, gastando donde más rédito político se obtenga, sin importar ninguna
                otra consideración.
                <br>
                <br />
                Para conseguir la prácticamente total libertad para gestionar sin ningún control presupuestario,
                simplemente se trata de asegurarse que en las bases de ejecución del presupuesto, la vinculación
                jurídica de los créditos iniciales sea la del mayor nivel posible, es decir que se permita reasignar
                créditos desde prácticamente cualquier programa a cualquier otro del misma área, consiguiendo de esta
                forma que la Junta de Gobierno Local este legitimada para efectuar estos cambios sin necesidad de
                aprobación del Pleno.
                <br>
                <br />
                Este descontrol presupuestario es permitido por los Grupos Municipales que sistemáticamente aprueban los
                presupuestos, a pesar de los reiterados incumplimientos de los acuerdos obtenidos en los presupuestos
                previos. Cuando te engañan una vez puedes pasar por crédulo, pero cuando lo hacen en reiteradas
                ocasiones se debería revisar la credibilidad de tus supuestos socios presupuestarios antes de volverles
                a aprobar un nuevo presupuesto.
                <br>
                <br />
                Si los presupuestos recogen las previsiones de ingresos y gastos, la liquidación presupuestaria es el
                reflejo de lo que realmente se ha ingresado y gastado en el año y, junto con la Cuenta General,
                constituyen elementos claves en la rendición de cuentas del equipo de gobierno y su control por parte de
                los representantes de los ciudadanos.
                <br>
                <br />
                La rendición de cuentas es uno de los pilares de un verdadero Gobierno Abierto, y por tanto debería ser
                un aspecto clave para valorar de forma objetiva la gestión de nuestro Ayuntamiento, pero nos encontramos
                con serios problemas en la presentación de estas Cuentas Generales:
                <br>
                <br />

                <strong>1.</strong> Se hacen con un retraso considerable, se han aprobado las del 2019 y 2020 en el
                Pleno de Noviembre.
                <br>
                <strong>2.</strong> Se presentan a exposición pública en formato papel, algo difícil de entender en
                pleno siglo XXI, lo
                que prácticamente imposibilita un estudio detallado de los estados de liquidación de ingresos y gastos.
                <br>
                <strong>3.</strong> El propio informe de Intervención reconoce que se incumplen principios contables y
                enumera año tras
                año los problemas con los que se encuentra de forma repetitiva, sin que parezca existir una intención de
                mejora en los aspectos que se informan.
                <br>
                <br />
                Seria por tanto de esperar que esta aprobación de Cuentas Generales por el Pleno fuese uno de los
                asuntos primordiales a tratar a lo largo del año, quizá en el Debate sobre el Estado de la Ciudad que
                debería celebrarse cada año, como recoge el Reglamento Orgánico Municipal, y del que nadie parece querer
                acordarse.
                <br>
                <br />
                Por el contrario, la aprobación de las Cuentas Generales se incluye como un punto más en un Pleno
                Ordinario, con las limitaciones de tiempo para el debate que ello conlleva, pero ya decimos que ni a
                Gobierno ni a Oposición parece interesarles hacerlo de otra forma.
                <br>
                <br />

                Así en el Pleno de noviembre se aprobaron las Cuentas Generales del 2019 y del 2020, presentadas en dos
                puntos, pero tratadas como uno solo (para qué perder más tiempo en asuntos tan nimios).
                <br>
                <br />

                Las Cuentas Generales se aprueban con los votos favorables del PSOE, IU, PODEMOS y GANEMOS, curiosamente
                IU anuncia en su primera intervención que votará en contra por entender que existen facturas de AQUALIA
                con las que no esta de acuerdo, parece que antes de la votación le convencieron de la bondad de estas
                facturas, cambiando el sentido de su voto.
                <br>
                <br />
                Por su parte GANEMOS justifica su voto a favor basándose en que si la Intervención ha informado
                favorablemente las Cuentas es motivo suficiente para ellos (parece que no han leído en detalle el
                informe de Intervención).
                <br>
                <br />
                Desde el Observatorio Ciudadano Municipal mostramos nuestra profunda decepción con el tratamiento que
                desde nuestro Ayuntamiento se hace de la gestión presupuestaria y consideramos que se pierde una
                magnífica herramienta de al menos tener constancia de los problemas que conlleva la gestión de las
                diferentes áreas de servicios que presta el Ayuntamiento para tratar de mejorar año tras año.
                <br>
                <br />
                Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
            </p>
        </div>
    </div>
</div>


<app-footer></app-footer>