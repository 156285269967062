<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    Lo que se ve, y lo que no se ve…
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 10 septiembre 2021</li>
                </ul>
            </div>

            <p>
                Es probable que dentro de unos días el pleno del Ayuntamiento de Jerez apruebe la solicitud
                para 2022 de un nuevo préstamo de 9 millones de euros, para el pago de sentencias a pagar a
                antiguos proveedores, con la posibilidad de que el préstamo total aumente hasta los 19 millones
                de euros.
                <br />
                <br />
                También es probable que desde el equipo de gobierno se haga un esfuerzo importante de
                comunicación, para
                explicarle a los jerezanos los efectos más inmediatos de este nuevo préstamo, “lo que se ve”:
                que la
                operación permite reducir el enorme gasto por intereses que pagamos por esas sentencias, y que
                eso ayuda
                a que la situación económica de Jerez mejore…
                <br />
                <br />
                Y tendrán razón…
                <br />
                <br />
                A efectos prácticos, lo que vamos a aprobar permite que cambiemos una deuda, la de las
                sentencias, por una
                deuda por el mismo importe, pero con un tipo de interés mucho más bajo. El ahorro es evidente,
                por la
                reducción de intereses a pagar en años venideros, y no hay razón para negarse a la operación.
                <br />
                <br />
                Pero lo que no es probable que desde el equipo de gobierno les cuenten son los efectos de largo
                plazo, “los que no se ven”. Es más, la experiencia de las últimas operaciones realizadas apunta
                a que existirá mucho interés (valga la redundancia…) desde la alcaldía para desviar la atención
                de los ciudadanos de “lo que no se ve”. Déjenme que se lo explique.
                <br />
                <br />
                No creo que les expliquen que, “curiosamente”, y como consecuencia de las operaciones de nuevos
                préstamos aprobadas durante los últimos meses (y hablamos de casi 150 millones de euros de
                deuda…), el equipo de gobierno se garantiza que hasta el final de su mandato no tendrá que
                reducir el volumen global de deuda. De hecho, la previsión es que la deuda seguirá aumentando…
                <br />
                <br />
                “Curiosamente” de nuevo, el próximo pago neto de la deuda financiera no se producirá hasta…
                junio de 2023, “curiosamente” después de las próximas elecciones municipales…
                <br />
                <br />
                Muchas “curiosidades”, ¿no creen?
                <br />
                <br />
                Es probable que les expliquen desde el gobierno que, gracias a estas operaciones, el
                ayuntamiento podrá realizar un montón de inversiones, desde ahora hasta las próximas elecciones.
                De hecho, es curioso observar de un tiempo a esta parte la multiplicación de presencia en medios
                sociales de determinados miembros de la alcaldía, anunciando, un día sí y otro también, una
                nueva inversión (con el concepto tan curioso que de “inversión” parecen tener…). “Lo que se ve”…
                <br />
                <br />
                ¿Y dónde está “lo que no se ve”?
                <br />
                <br />
                Pues lo que no tienen mucho interés por que se sepa es que, pasadas las próximas elecciones, el
                siguiente equipo de gobierno se va a encontrar, en forma de hipoteca, que en 2023 habrá que
                reducir la deuda en 23 millones, 40 en 2024, 49 en 2025, 46 en 2026… Podría seguir, pero
                quédense con que, entre 2023 y 2032, entre todos los jerezanos pagaremos 43 millones de deuda de
                media cada año. De todo eso parece que no tienen mucho interés por que los ciudadanos se
                enteren…
                <br />
                <br />
                Les contarán que los préstamos aprobados no nos daban otra alternativa que retrasar el pago de
                la deuda hasta pasado unos años, y es posible que sea verdad. Lo que no creemos que les
                expliquen es que, una cosa es que se retrase el pago de la deuda, y otra muy distinta que no
                aprovechemos ese tiempo extra que hemos ganado para recuperar la maltrecha economía municipal,
                adecuar los gastos del ayuntamiento a la realidad de nuestros ingresos, y mejorar nuestros
                vergonzosos periodos de pagos a proveedores. En vez de todo eso, hay razones para temer que esta
                oportunidad se utilice para lanzarse a una campaña electoral continua de aquí a 2023, comprando
                votos con un dinero que, tarde o temprano, deberemos devolver…
                <br />
                <br />
                Hace ya casi dos siglos que el gran pensador francés, Frédéric Bastiat, nos enseñó que en
                economía era casi tan importante saber ver “lo que se ve”, como “lo que no se ve”. Parece que en
                los tiempos que corren existe un interés desmedido porque los ciudadanos sólo vean “lo que se
                quiere que vean”, antes que lo que de verdad “deberían ver”…
                <br />
                <br />
                Desde el OCM Jerez seguimos comprometidos con, dentro de nuestras posibilidades, informar a los
                jerezanos tanto de “lo que se ve”, como de “lo que no se ve”…
                <br />
                <br />
                Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
            </p>
        </div>
    </div>
</div>


<app-footer></app-footer>