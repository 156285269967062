import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tsunami-economico',
  templateUrl: './tsunami-economico.component.html',
  styleUrls: ['./tsunami-economico.component.css']
})
export class TsunamiEconomicoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
