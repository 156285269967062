import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jerez-no-les-interesa',
  templateUrl: './jerez-no-les-interesa.component.html',
  styleUrls: ['./jerez-no-les-interesa.component.css']
})
export class JerezNoLesInteresaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
