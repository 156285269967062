<app-header></app-header>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title-item text-center">
          <h4 id="demotext">Ponte en contacto con nosotros</h4>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/" style="color: white">Inicio </a></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== PAGE TITLE PART ENDS ======-->

<!--====== CONTACT DETAILS PART START ======-->
<div class="contact-details-area pt-90 pb-120">
  <div class="container">
    <div class="row">
      <div class="col-lg-2">
      </div>

      <div class="col-lg-8">
        <div class="contact-info mr-30 mt-30">
          <div class="contact-item-1">
            <div class="contact-info-item text-center">
              <i class="fal fa-phone"></i>
              <h5 class="title">Teléfono</h5>
              <p>609 53 68 00</p>
            </div>
            <div class="contact-info-item text-center">
              <i class="fal fa-envelope"></i>
              <h5 class="title">Email</h5>
              <p>info@ocmjerez.org</p>
            </div>
          </div>
          <div class="contact-item-1">
            <div class="contact-info-item text-center">
              <h5 class="title">Twitter</h5>
              <a href="https://twitter.com/ocmjerez" style="padding-left: 4px" target="_blank" rel="nofollow"> <i
                  class="fab fa-twitter fa-4x"></i>
              </a>
            </div>
            <div class="contact-info-item text-center">
              <h5 class="title">Facebook</h5>
              <a href="https://www.facebook.com/OcmJerez/" style="padding-left: 4px" target="_blank" rel="nofollow"> <i
                  class="fab fa-facebook fa-4x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== CONTACT DETAILS PART ENDS ======-->
<app-footer></app-footer>