<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    EL TSUNAMI (ECONÓMICO) LLEGARÁ A JEREZ EN 2024…
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 19 marzo 2022</li>
                </ul>
            </div>

            <p>
                No sé si tendrá razón un buen amigo, que me dijo que “Jerez es como Mónaco, pero en tieso”… Lo que sí sé
                es que los jerezanos recordaremos en 2024 a los que siguen alimentando el tsunami económico que se nos
                viene encima…
                <br>
                <br />
                Ahora que ya he conseguido atraer su atención, les explicaré qué quiero decir.
                El próximo lunes 21, el pleno del ayuntamiento de Jerez votará la aprobación de un nuevo préstamo de 7
                millones de euros con Hacienda, tras lo que nuestra deuda financiera seguirá creciendo, ya muy por
                encima de los 900 millones de euros (la deuda de Emuvijesa también hay que contarla, Delegada, y la
                mayoría la tenemos con Hacienda, no con los bancos…).
                <br>
                <br />
                Para que Hacienda nos dé este préstamo, nos piden que aprobemos un nuevo plan de ajuste. Para aquellos
                que no lo sepan, el plan de ajuste es el documento en el que nuestra Alcaldesa y la Delegada explican
                cómo y cuándo van a pagar los más de 900 millones de deuda.
                <br>
                <br>
                Y es en ese plan de ajuste dónde nuestra Alcaldesa y su Delegada (junto a un pequeño e imprescindible
                grupo de funcionarios de alto nivel…) están generando los “seísmos económicos” que nos hacen decir que
                en 2024 llegará a Jerez un “tsunami” de efectos dramáticos. Se lo explico a continuación, detallando lo
                que este nuevo plan de ajuste supondrá para todos los jerezanos:
                <br>
                <br />
                1. Como ya les expliqué hace unos días (<a href="https://w.ocmjerez.org/MotivosPreocupacion">Motivos
                    para
                    la preocupación </a>) el plan de ajuste pone de manifiesto que en 2021 los gastos corrientes (260
                millones) fueron superiores en 48
                millones a los ingresos corrientes (212 millones). Un déficit para el que la Alcaldesa y su Delegada ya
                van tarde para explicarnos a qué se deben. Por si no teníamos bastante lastre de años anteriores, parece
                que 2021 nos va a dejar otro buen “pedrusco”.
                <br>
                <br>
                2. Pero, siendo grave, lo de 2021 es lo de menos. Lo grave es que, comparado con las previsiones de
                gastos e ingresos que hace el plan de ajuste, el cuento “Alicia en el País de las Maravillas” podría ser
                calificado de novela histórica. Veámoslo.
                <br>
                <br />
                3. Nuestra “Reina de Corazones” particular (léase Alcaldesa o Delegada, tanto monta, monta tanto…) nos
                dice que durante los próximos 10 años los gastos corrientes del ayuntamiento sólo crecerán un 4% (de 219
                a 228 millones). Me imagino que eso será porque durante los próximos 10 años Jerez va a ser un “paraíso
                económico”, el único lugar del planeta en el que no habrá inflación. Y no me malinterpreten. No seré yo
                quien esté en contra de la contención (cuando no reducción) del gasto público. Pero, viendo la forma de
                actuar de estos seis años de nuestra “Reina de Corazones”, se me hace difícil imaginar que vaya a
                perderse una “buena foto” por ejercer una prudencia económica de la que nunca ha hecho gala.
                <br>
                <br />
                4. Pero es que, si por si lo anterior era poco, cuando llegamos a la parte de los ingresos del plan de
                ajuste es cuando parece que en su preparación haya habido barra libre de sustancias psicotrópicas. Sólo
                así se puede explicar este “cuento”, de que en 2022 los ingresos van a crecer un 13% con respecto a 2021
                (de 212 a 239 millones), cuando en los últimos años prácticamente no han crecido… Y que, a partir de
                ahí, crecerán muy por encima de los gastos, de forma que en 2032 los ingresos serán ¡un 34% superiores a
                los de 2022 (de 240 a 320 millones…)! Debe de ser que nuestra Reina de Corazones y sus fieles han
                encontrado el “Bolsillo Mágico de Doraemon”, aunque el único bolsillo del que nuestros dirigentes suelen
                echar mano es del de todos y cada uno de nosotros…
                <br>
                <br />
                5. Y el triste golpe de realidad es el que, una vez los gastos sean los que tengan que ser, y las
                sustancias psicotrópicas hayan dejado de tener efecto en los ingresos, nos vamos a pegar todos los
                jerezanos con la factura de la “fiesta” de nuestra Reina de Corazones… Porque, como ya les explicamos
                hace unos meses (<a href="https://w.ocmjerez.org/loQueSeVe">Lo que se ve, y lo que no se ve… </a>),
                entre 2024 (¡qué casualidad
                que es justo cuando haya acabado el mandato de nuestra Reina de Corazones…!) y 2034, nos tocará pagar de
                media más de 46 millones de deuda cada año. Y entonces nos acordaremos de todas las patadas hacia
                delante que nuestra Alcaldesa y la Delegada llevan años pegándole a los problemas de Jerez, mientras nos
                prometen que “están salvando la economía de Jerez”, “que este es el gobierno de las inversiones (a
                arreglar una acera lo llaman inversión…)” y que “si algo malo hay es siempre por culpa de la anterior
                alcaldesa”… Es lo que hay…
                <br>
                <br />
                No sé si tendrá razón un buen amigo, “jerezano de toda la vida”, que hace unos días me dijo que “Jerez
                es como Mónaco, pero en tieso”… Lo que sí sé es que los jerezanos recordaremos en 2024 a los que siguen
                alimentando el tsunami económico que se nos viene encima…
                <br>
                <br />
                Desde el OCM Jerez seguiremos explicándoselo con palabras que todos entiendan.
                <br>
                <br />
                Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
            </p>
        </div>
    </div>
</div>


<app-footer></app-footer>