<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    ¿A QUIÉN LE IMPORTA JEREZ?
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 7 febrero 2022</li>
                </ul>
            </div>

            <p>
                Hace casi cuarenta años que la cantante Alaska, junto con el grupo Dinarama, hizo tremendamente popular
                la canción “A quién le importa”. Recomiendo a todos aquellos que son tan insultantemente jóvenes como
                para no recordar esta canción que la busquen en Youtube…
                <br>
                <br />
                Eso es lo que, por desgracia, tenemos que preguntarnos cada día que pasa en Jerez sin que se presente el
                presupuesto de 2022, del que ya llevamos un mes consumido, y sin que parezca que vayamos a tener
                respuesta durante las próximas semanas. Y les recuerdo que, una vez aprobado aquí, el presupuesto tendrá
                que ser aprobado por el Ministerio de Hacienda, por lo que tendremos suerte si estos presupuestos llegan
                a estar en vigor algo más de seis meses (a mí no me sorprendería que nos pasáramos otro año sin
                presupuesto)…
                <br>
                <br />
                Veamos para qué ha habido tiempo durante estos últimos meses en Jerez, y para qué no…
                <br>
                <br>
                Desde el pasado mes de octubre nuestra Delegada de Hacienda, Laura Álvarez, ha tenido tiempo para
                aparecer en medios sociales en innumerables fotos de inauguraciones de obras, obritas y reparaciones, y
                fastos varios. Si no me creen, revisen los medios…
                <br>
                <br />
                De lo que parece que no ha tenido todavía tiempo es de sentarse en su despacho, para hacer aquello para
                lo que todos los jerezanos le pagamos su buen sueldo, que no es otra cosa que ocuparse de las finanzas
                de nuestra ciudad y, entre otras cosas, presentar y aprobar el presupuesto de nuestra ciudad. Es decir,
                que no ha tenido tiempo para hacer su trabajo…
                <br>
                <br>
                No quiero hacerles perder el tiempo explicándoles, una vez más, la tremenda dejación de funciones en la
                que nuestra Delegada incurre, y el empeoramiento de la gravísima situación económica a la que su
                inacción nos aboca…
                <br>
                <br />
                Pero es que ya empieza a resultar conveniente recordar que las finanzas de una ciudad con más de
                doscientos mil habitantes y doscientos millones de gastos están en manos de una persona que es, tal y
                como reza la propia web municipal, Licenciada en Ciencias de la Actividad Física y el Deporte, sin
                ninguna experiencia previa en la gestión económica. Creo que sobran los comentarios adicionales…
                <br>
                <br />
                Sin duda puede ser discutible la labor del anterior Delegado de Economía, Santiago Galván, pero al menos
                él tenía una formación previa que parecía adecuada para desempeñar la función que se le encomendó. Quién
                nos iba a decir que acabaríamos, con todos sus defectos, echándole de menos, y es que cada vez nos
                conformamos con menos…
                <br>
                <br />
                Pero es que no sólo se trata de que la Delegada de Hacienda haga dejación de funciones. Es que todos nos
                deberíamos preguntar cómo es posible que nuestra Alcaldesa esté tranquilamente, y cobrando su buen
                sueldo, mientras que su Delegada de Hacienda sigue sin hacer su trabajo…
                <br>
                <br />
                Y quizás ya va siendo hora de preguntarse cómo es posible que todos los jerezanos estemos pagando esos
                buenos sueldos a ese pequeño grupo de empleados públicos de alta cualificación que asisten
                tranquilamente a esta situación, mientras que sus buenas nóminas siguen llegando a sus cuentas
                corrientes a final de mes, ¿tampoco ellos tienen nada que decir o hacer?...
                <br>
                <br />
                Pero bueno, será que, volviendo al comienzo de esta tribuna de opinión, hemos llegado a un punto en el
                que Jerez ya no le importa a nadie. Ya nos los dijo Alaska hace casi cuarenta años…
                <br>
                <br />

                Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
            </p>
        </div>
    </div>
</div>


<app-footer></app-footer>