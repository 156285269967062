<footer>
  <div class="container">
    <div class="row">

      <div class="col-lg-5">
        <div class="footer-copyright">
          <p><span>OCM Jerez</span> - 2021 Ver 1.2.1</p>
        </div>
      </div>

      <div class="col-lg-2">
        <a href="https://www.facebook.com/OcmJerez/" style="padding-right: 4px" target="_blank" rel="nofollow"><i
            class="fab fa-facebook fa-3x"></i>
        </a>
        <a href="https://twitter.com/ocmjerez" style="padding-left: 4px" target="_blank" rel="nofollow"><i
            class="fab fa-twitter fa-3x"></i>
        </a>
      </div>

    </div>
  </div>
</footer>

<div class="back-to-top back-to-top-2">
  <a href="">
    <i class="fas fa-arrow-up"></i>
  </a>
</div>