import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuentas-generales20192020',
  templateUrl: './cuentas-generales20192020.component.html',
  styleUrls: ['./cuentas-generales20192020.component.css']
})
export class CuentasGenerales20192020Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
