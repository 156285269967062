<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    Cuentas Generales del Ayuntamiento. Las irregularidades que no cesan…
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 16 octubre 2021</li>
                </ul>
            </div>

            <p>
                Imagine por un momento que vuelve a ser un niño en edad escolar… <br />
                Imagine que, año tras año, suspende siempre la misma asignatura… <br />
                Imagine que el examen es siempre el mismo, y que usted se equivoca en las mismas preguntas, dando las
                mismas respuestas erróneas una y otra vez… <br />
                Sus padres posiblemente no estarían muy satisfechos con usted, ¿no?...
                <br />
                <br />
                Mire, no le queremos aburrir con un informe contable detallado sobre las cuentas generales de 2019 y
                2020 del Ayuntamiento de Jerez, que en breve serán sometidas a la aprobación del Pleno. <br />
                Pero sí queremos explicarle que existe un “niño”, llamado Ayuntamiento, a cuya cabeza está Mamen
                Sánchez, que cada año tiene un examen, llamado “Cuenta General”.
                <br />
                <br />
                Que cada año, las preguntas del examen son las mismas, y se llaman “principios contables”. Que son los
                “mandamientos” que obligatoriamente el Ayuntamiento debe cumplir para hacer el examen y aprobarlo.
                <br />
                Si cuando estábamos en el colegio a cada uno de nosotros nos hubieran puesto, una y otra vez, el mismo
                examen con las mismas preguntas, estará usted de acuerdo en que lo más sencillo era acabar sacando
                matrícula de honor, ¿no?
                <br />
                <br />
                <span>¿Y cuáles son las notas que saca el Ayuntamiento en la Cuenta General? No lo decimos nosotros, lo
                    dice
                    su “profesor”, llamado Intervención, que cada año califica el examen. <br />Veamos las notas:
                    <br />
                    <br />
                </span>
                <img class="center"
                    src="https://res.cloudinary.com/dabrencx7/image/upload/v1634356803/blog/tablaPrincipiosContables_e8r3va.png"
                    alt="tablaPrincipiosContables">
                <br />
                <br />
                De nueve preguntas que tenía el examen en 2014, el Ayuntamiento sólo “acertó” en tres de nueve…
                SUSPENDIÓ… <br />
                Pero es que desde 2014 nuestro “niño” (Ayuntamiento) lleva sacando un 3 de 9, TODOS LOS AÑOS… ¿Cree
                usted que sus “padres” (es decir, los jerezanos), podemos estar contentos?
                <br />
                <br />
                Y ahora sí me tiene usted que permitir que nos pongamos un poco más serios y más técnicos. <br />
                El resumen de todo esto es que no sólo la situación económica de Jerez es dramática
                por lo que ya dicen sus cuentas generales, sino también por lo mucho que callan y
                ocultan.
                <br />
                <br />
                No es aceptable que el Ayuntamiento de la quinta ciudad de Andalucía por número de habitantes presente,
                año tras año, semejante lista de irregularidades contables… <br />
                No es aceptable que en siete años la mejora haya sido, NINGUNA… <br />
                Produce vergüenza que, al tiempo que estas cosas ocurren, desde los medios de comunicación, los
                responsables últimos de esta situación nos “cuenten” que se ha revertido la situación económica del
                Ayuntamiento. <br />
                Es usted libre de extraer sus conclusiones.

                <br />
                <br />
                Lo triste es que, con gran probabilidad, estas cuentas generales serán aprobadas por la mayoría de
                concejales del Pleno del Ayuntamiento. Ellos sabrán las responsabilidades, cuando menos morales, que
                están dispuestos a asumir ante todos los jerezanos.
                <br />
                <br />
                También es triste observar, la “benevolencia”, y casi “complacencia” con la que la Intervención, a la
                que los ciudadanos nos encomendamos para velar por la pulcritud de nuestras cuentas, se limita a listar
                las irregularidades repetidas hasta la saciedad, año tras año.

                <br />
                <br />
                Y por último, causa una profunda tristeza comprobar que, a pesar de las reiteradas irregularidades, los
                órganos últimos de supervisión (Cámara de Cuentas de Andalucía y Tribunal de Cuentas) no hacen,
                literalmente, nada, por defender los derechos de los jerezanos. <br />
                Ellos sabrán…

                <br />
                <br />
                Al menos, desde el OCM Jerez seguiremos intentando contarle estas cosas, y hacerlo de forma que
                cualquiera pueda entenderlas.
                <br />
                <br />


                Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
            </p>
        </div>
    </div>
</div>


<app-footer></app-footer>