import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-motivos-preocupacion',
  templateUrl: './motivos-preocupacion.component.html',
  styleUrls: ['./motivos-preocupacion.component.css']
})
export class MotivosPreocupacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
