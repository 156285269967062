<app-header></app-header>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Error</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">Error</li> -->
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== ERROR PART START ======-->
<div class="error-area pt-120 pb-115">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="error-item text-center">
                    <img src="assets/images/404.jpg" alt="">
                    <h2 class="title">¡Parece que esta pagina <br> no existe!</h2>
                    <span>Intentalo con otra.</span>
                </div> <!-- error item -->
            </div>
        </div>
    </div>
</div>
<!--====== ERROR PART ENDS ======-->
<app-footer></app-footer>
