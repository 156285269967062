<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="blog-dteails-content blog-border">
          <!-- Inicio título -->
          <div class="blog-details-top">
            <h1 class="title" style="color: rgb(11, 20, 156)">
              Lo que no te han contado del presupuesto…
            </h1>
            <ul>
              <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
              <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
              <li><i class="fal fa-calendar-alt"></i> 16 agosto 2021</li>
            </ul>
          </div>
          <!-- Final título -->

          <!-- Inicio primer parrafo -->
          <p>
            Hace ya más de dos semanas que la Delegada de Economía del Ayuntamiento de Jerez, Laura Álvarez, compareció
            ante los medios para informar del, a su juicio, excelente resultado de la liquidación del presupuesto de
            2020: 30 millones euros de superávit presupuestario, mejora de 11 millones del excedente de tesorería,
            reducción de la deuda con proveedores…
            <br />
            <span style="color:red;font-weight:bold">¿Qué más se podía pedir?</span>
            <br />
            <br />
            En una administración que, en vez de presumir de transparencia, realmente la llevara a cabo, hace ya mucho
            que el detalle de la información estaría a disposición de todos los ciudadanos de Jerez. Por desgracia, a la
            hora de escribir estas líneas seguimos esperando que el “Portal de ¿Transparencia?” de nuestro ayuntamiento
            publique esa información.
            <br />
            Pero va siendo hora de que analicemos los anuncios realizados por nuestra Delegada, y veamos hasta qué punto
            son tan “buenas noticias”. Empecemos.
            <br />
            <br />
            Laura Álvarez tiene razón, y el resultado presupuestario de 2020 fue positivo en 30 millones de euros.
            <br />
            Lo que, “casualmente” se le olvidó a nuestra Delegada es que 28 millones de esos 30 corresponden al aumento
            de la deuda de todos los jerezanos, que tarde o temprano acabaremos pagando.
            <br />
            Considerar esos 28 millones como
            ingreso, y presumir de ello es, cuando menos, cuestionable.
            <br />
            Pero sigamos.
            <br />
            <br />
            Si a los 30 millones de resultado positivo restamos esos 28 millones de mayor deuda nos quedamos con 2
            millones positivos (les recuerdo que Jerez ingresa unos 200 millones al año, por lo que 2 millones no es
            como para tirar cohetes…).
            <br /> Pero es que no podemos olvidar que históricamente el Ayuntamiento deja sin cobrar
            varios millones de euros. A falta de datos oficiales, no es excesivo estimar este importe entre 5 y 10
            millones de euros por año.
            <br />
            <br />
            Efectivamente, si a los 30 millones anunciados por la Delegada restamos los 28 de deuda y los ingresos que
            al final no cobraremos llegamos a la conclusión de que lo que se nos anunciado como un éxito no deja de ser
            una nueva pérdida para Jerez, que deberemos pagar entre todos…
            <br />
            <br />
            Pero hay más…
            Y es que la Delegada también se congratuló de la mejora de 11 millones de la posición de tesorería
            (Remanente de Tesorería). ¿Es esa una buena noticia? Mucho nos tememos que no…
            <br />
            Jerez lleva desde hace años convirtiendo deuda comercial o con administraciones públicas en deuda financiera
            con el Ministerio de Hacienda. Por eso, de la mejora de 11 millones de la que nos informan hay que volver a
            restar los 28 millones de mayor deuda que hemos mencionado. Y sí, al hacerlo resulta que la tesorería del
            ayuntamiento no mejora sino que, como los años anteriores, sigue empeorando…
            Por desgracia, eso no es todo…
            <br />
            <br />
            Laura Álvarez mostró su alegría porque se haya reducido la deuda con proveedores… olvidando que la deuda
            total del ayuntamiento (proveedores, administraciones y financiera) sigue su aumento imparable desde hace
            años.
            <br />
            Como informó el OCM Jerez hace poco, de más de 800 millones a finales de 2014, a más de 1.000 a
            finales de 2020… No, no es casualidad que el periodo medio de pago a proveedores tenga una permanente
            tendencia de empeoramiento, por mucho que, de mes en mes, pasemos deuda comercial a deuda financiera.
            Nos encantaría poder decir lo contrario, pero no es así.
            <br />
            Ninguno de los anuncios del equipo de gobierno de
            hace unos días describen la triste realidad en la que el ayuntamiento vive desde hace años.
            Pero es que aún hay más…
            <br />
            <br />
            El pasado 26 de julio se aprobó una nueva reestructuración de la deuda del ayuntamiento, dando una nueva
            patada hacia delante a la misma. “Casualmente” de nuevo, no será hasta junio de 2023, pasadas las elecciones
            municipales, cuando tengamos que empezar a pagar más de 24 millones al año (todavía estamos esperando que
            nos expliquen de dónde sacarán el dinero)…
            <br />
            ¿Sabían ustedes que, para pedir esa reestructuración, era obligatorio enviar a Hacienda la liquidación de
            2020 de la que estamos hablando?
            <br />
            <br />
            ¿Sabían ustedes que, “casualmente”, los concejales no recibieron esa información antes del pleno en el que
            se aprobó la reestructuración de la deuda, pero que, “casualmente”, al día siguiente sí que se pudo enviar
            la información a Hacienda, sobre la que la Delegada hizo su comparecencia pública el día 28 de julio?
            <br />
            ¿Sabían ustedes que, “casualmente” la información sobre la que se basa la liquidación estaba disponible
            desde finales de abril?
            <br />
            <br />
            ¿Y sabían ustedes que, “casualmente” la Intervención municipal emitió su informe preceptivo a primeros del
            mes de junio”
            ¿O que, “casualmente”, la liquidación fue firmada por la alcaldesa más de un mes antes de que se aprobara la
            reestructuración de la deuda sin que, a pesar de ello, se informara a los concejales, representantes de
            todos los ciudadanos, de una información que era obligatoria para reestructurar la deuda?
            Parecen demasiadas “casualidades”, demasiadas preguntas sin respuesta…
            <br />
            <br />
            Decía Thomas Jefferson que “el precio de la Libertad es la eterna vigilancia”. Y esa responsabilidad nos
            corresponde a todos los ciudadanos de Jerez.
            <br />
            <br />
            Desde el OCM Jerez seguiremos con nuestro compromiso para, a pesar de todas las dificultades que nos ponen
            para acceder a información a la que todos los ciudadanos tenemos derecho, darles a conocer la realidad de lo
            que, por desgracia, pasa en nuestro ayuntamiento.

            <br />
          </p>
          <!-- Final primer parrafo -->



        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>