<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="blog-dteails-content blog-border">
            <div class="blog-details-top">
                <h1 class="title" style="color: rgb(11, 20, 156)">
                    A vueltas con Comujesa y los autobuses…
                </h1>
                <ul>
                    <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
                    <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
                    <li><i class="fal fa-calendar-alt"></i> 23 septiembre 2021</li>
                </ul>
            </div>

            <p>
                A vueltas con Comujesa y los autobuses…
                En julio de 2020 nuestro Ayuntamiento anunció su intención de trasladar parte de los
                servicios prestados por Comujesa a la parcela de la Ciudad del Transporte que en su
                día iba a ocupar el fallido proyecto de Zahav. En concreto, el servicio de autobuses y
                el de calas y alumbrado público.
                <br />
                <br />
                En Andalucía Información se hablaba de un “espacio multiservicios que… permitiría
                unificar en una misma sede a todos estos servicios que actualmente se encuentran
                repartidos en solares y naves que no son de titularidad municipal, lo que obliga al
                Ayuntamiento a hacer un importante desembolso anual en concepto de
                arrendamientos”.
                <br />
                <br />
                Hasta ahí todo bien con la idea, ¿no? Parece una medida sensata para reducir los
                gastos de arrendamientos, en una compañía como Comujesa, con una muy delicada
                situación patrimonial.
                Pero a partir de ahí empiezan las dudas, y las cuestiones “curiosas”, para las que los
                ciudadanos de Jerez agradeceríamos respuestas de nuestros dirigentes municipales.
                <br />
                <br />
                Para empezar, ¿se sabe cuánto costarán las obras de adaptación de los nuevos
                terrenos? Porque, dependiendo del coste de esa inversión, el traslado podría tener
                sentido, o dejar de tenerlo por completo… De hecho, lo lógico hubiera sido saber el
                coste total de la inversión antes de ceder el terreno…
                <br />
                <br />
                Hay más. Hasta donde sabemos, nadie ha explicado cómo se financiará semejante
                inversión, y no está el Ayuntamiento para grandes obras, ahogado por más de mil
                millones de deuda…
                <br />
                <br />
                Y las dudas ya terminan de dispararse cuando hace pocos días el Pleno del
                Ayuntamiento aprobó las Cuentas Anuales de 2020, y empezamos a conocer lo que
                dicen las mismas sobre este tema.
                En primer lugar, lo que se publicitó en medios como “espacio multiservicios” para
                unificar los prestados por Comujesa resulta que se destina únicamente para el servicio
                de autobuses.
                <br />
                <br />
                Vamos recortando, pero aceptemos que siga teniendo sentido.
                Pero es que, si en julio de 2020 la Junta de Gobierno Local decidió ceder el terreno a
                Comujesa para los autobuses, es lógico pensar que se estaba estudiando la operación
                desde hacía bastante tiempo, ¿no?...
                <br />
                <br />
                ¿Cómo es posible entonces que, como leemos en las mismas Cuentas Anuales, se
                informe de que en diciembre de 2019 (pocos meses antes de la cesión del nuevo
                terreno), Comujesa renovase el contrato de alquiler de las actuales cocheras del
                Portal… hasta finales de 2028… con un coste total de no menos de 400 mil euros?
                ¿Qué vamos a hacer ahora, con un alquiler comprometido hasta 2028, y
                simultáneamente teniendo que acometer la inversión del nuevo terreno, cuyo coste y
                forma de financiación desconocemos?
                <br />
                <br />
                ¿O es que hasta 2028 no nos vamos a trasladar a la nueva ubicación?
                Todo ello, por no volver a plantear cuestiones “curiosas” que siguen sin responder
                desde hace años.
                <br />
                <br />
                ¿Ustedes saben que para repostar nuestros autobuses, que se supone apostaban por
                formas de energía menos contaminantes, hay que llevarlos a la única (y afortunada…)
                estación de servicio en Jerez que dispone del combustible de Gas Natural Comprimido
                (GNC) que usan nuestros autobuses?
                ¿Ustedes harían quince kilómetros de ida, y otros tantos de vuelta, para repostar su
                coche? Pues eso es lo que hacen nuestros autobuses, que apuestan por el medio
                ambiente…
                <br />
                <br />
                Por si esa distancia fuera poca, si seguimos repostando nuestros autobuses de esta
                forma tan “curiosa”, pasaremos de quince a veinte kilómetros por trayecto. No parece
                ni eficiente, ni eficaz ni, por supuesto, “ecológico”…
                <br />
                <br />
                No sabemos si en algún momento conseguiremos que el Ayuntamiento conteste sobre
                estas cuestiones, no ya al OCM Jerez, sino a todos los ciudadanos. Pero de lo que no
                les quepa la menor duda es de que seguiremos realizando todas las preguntas, por
                incómodas que sean, para que los jerezanos sepamos cuál es la verdadera situación
                del Ayuntamiento y sus empresas.
                <br />
                <br />
                Observatorio Ciudadano Municipal de Jerez (OCM Jerez)
            </p>
        </div>
    </div>
</div>


<app-footer></app-footer>