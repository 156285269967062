<app-header></app-header>

<div class="blog-standard-area pt-90 pb-120">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="blog-dteails-content blog-border">
          <!-- Inicio título -->
          <div class="blog-details-top">
            <h1 class="title" style="color: rgb(11, 20, 156)">
              Coste concesión de licencias en el Ayuntamiento de Jerez.
            </h1>
            <ul>
              <!-- <li><i class="fal fa-eye"></i> 232 Vistas</li>
              <li><i class="fal fa-comments"></i> 0 Comentarios</li> -->
              <li><i class="fal fa-calendar-alt"></i> 26 diciembre 2020</li>
            </ul>
          </div>
          <!-- Final título -->

          <!-- Inicio primer parrafo -->
          <p>
            El teniente de alcaldesa de Urbanismo, José Antonio Díaz, en
            declaraciones a la prensa (
            <a
              href="https://vivajerez.es/jerez/940515/urbanismo-tramita-este-ano-en-jerez-cerca-de-900-declaraciones-responsables/"
              target="_blank"
              >vivajerez</a
            >
            ), ha informado que desde la entrada en vigor de las dos nuevas
            ordenanzas que generalizan el uso de las declaraciones responsables
            y permiten iniciar de manera inmediata determinadas obras y
            aperturas de negocios, se han llevado a cabo 896 proyectos en Jerez
            mediante este procedimiento, que también ha permitido iniciar de
            manera exprés un total de 166 iniciativas empresariales.
            <br /><br />
            A partir de este dato desde el OCM tratamos de cuantificar el coste
            de la actividad de concesión de licencias para nuestro Ayuntamiento.
            <br /><br />
            Nos falta conocer las licencias concedidas hasta la entrada en vigor
            de las nuevas ordenanzas, por lo que hacemos una estimación, que
            podremos ajustar cuando tengamos datos definitivos.
            <br />
          </p>
          <!-- Final primer parrafo -->

          <!-- Inicio parrafo con imagen -->
          <div class="blog-details-bath">
            <h4 class="title">Personal adscrito al departamento</h4>
            <p>
              Según la última RPT (Relación de Puestos de Trabajo) estos son los
              puestos del departamento:
              <img src="assets/images/blog/entrada2/RPT.png" alt="RPT" />
            </p>

            <img
              src="assets/images/blog/entrada2/siglas.png"
              width="388"
              height="83"
              alt="siglas"
            />
          </div>
          <!-- Final parrafo con imagen  -->

          <!-- Inicio parrafo con imagen -->
          <div class="blog-details-bath">
            <h4 class="title">
              Ingresos por tasas licencias de aperturas, según estado de
              ejecución al 15 diciembre 2020
            </h4>
            <img
              src="assets/images/blog/entrada2/ingresoTasas.png"
              alt="tasas"
            />
          </div>
          <!-- Final parrafo con imagen  -->

          <!-- Inicio parrafo con imagen -->
          <div class="blog-details-bath">
            <h4 class="title">
              Gratificaciones recibidas por el personal del departamento, según
              estado de ejecución al 15 diciembre 2020:
            </h4>
            <img
              src="assets/images/blog/entrada2/gratificaciones.png"
              alt="gratificaciones"
            />
          </div>
          <!-- Final parrafo con imagen  -->

          <!-- Inicio parrafo con lista -->
          <div class="blog-details-bath">
            <h4 class="title">
              Estimaciones realizadas a falta de datos exactos:
            </h4>
            <ul>
              <li style="color: black">
                <i class="fal fa-arrow-alt-circle-right"></i> Salario base por
                persona = 20.000 euros/año
              </li>
              <li style="color: black">
                <i class="fal fa-arrow-alt-circle-right"></i> Porcentaje a pagar
                a la Seguridad Social = 33%
              </li>
              <li style="color: black">
                <i class="fal fa-arrow-alt-circle-right"></i> Estimación
                expedientes anteriores a la entrada en vigor nueva normativa =
                200
              </li>
            </ul>
          </div>
          <!-- Final parrafo con lista  -->

          <!-- Inicio parrafo con imagen -->
          <div class="blog-details-bath">
            <h4 class="title">Calculos obtenidos con los datos anteriores:</h4>
            <img
              src="assets/images/blog/entrada2/tablaCostes.png"
              alt="costes"
            />

            <p
              style="
                text-align: justify;
                color: black;
                padding-top: 4px;
                padding-bottom: 10px;
              "
            >
              Todos los datos y calculos utilizados se pueden ver en la
              siguiente hoja de calculo:
              <a
                href="https://docs.google.com/spreadsheets/d/1XgRcnJUNFLsD1vx7EAcgFvj2pB4hyGqM3pYFN8O8KvA/edit?usp=sharing"
                target="_blank"
              >
                datos y calculos utilizados</a
              ><br /><br />
            </p>
          </div>
          <!-- Final parrafo con imagen  -->

          <!-- Inicio parrafo con lista -->
          <div class="blog-details-bath">
            <h4 class="title">Puntos de mejora del calculo:</h4>
            <ul>
              <li style="color: black">
                <i class="fal fa-arrow-alt-circle-right"></i> Conocer el dato
                exacto del personal asignado al departamento.<br />
                Se ha estimado un salario base por persona de 20.000 euros, y no
                se ha sumado ninguna cantidad por <br />
                pago a la Seguridad Social por gratificaciones.
              </li>
              <li style="color: black">
                <i class="fal fa-arrow-alt-circle-right"></i> Conocer la
                cantidad exacta de expedientes tramitados.
              </li>
            </ul>
          </div>
          <!-- Final parrafo con lista  -->

          <!-- Inicio parrafo con imagen -->
          <div class="blog-details-bath">
            <h4 class="title">Actualización 26/12/2020:</h4>

            <p
              style="
                text-align: justify;
                color: black;
                padding-top: 4px;
                padding-bottom: 10px;
              "
            >
            <img
                src="assets/images/blog/entrada2/costesPersonal.png"
                alt="costes"
              />
           Costes de personal según la ejecución al 15/12/2020.
            </p>


            <p
              style="
                text-align: justify;
                color: black;
                padding-top: 4px;
                padding-bottom: 10px;
              "
            >
            <img
                src="assets/images/blog/entrada2/tasas.png"
                alt="costes"
              />
           Otras tasas que se podrian considerar como ingresos.
            </p>



          </div>
          <!-- Final parrafo con imagen  -->
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
