import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comujesa202109',
  templateUrl: './comujesa202109.component.html',
  styleUrls: ['./comujesa202109.component.css']
})
export class Comujesa202109Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
